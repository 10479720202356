import Vue from 'vue'
import { IAppModule, ILink, IView } from '@/common/types'
import { Access } from '@/common/enums'

export default class CalendarModule implements IAppModule {
  async init(): Promise<any> {
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: 'CalendarView', path: '/calendar', component: (await import('./views/CalendarView.vue')).default, access: Access.VisitViewer }

    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [
    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
    ]
  }
}
